import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBookSaved = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M12 22.08c-.3 0-.6-.07-.85-.21-1.87-1.02-5.15-2.1-7.21-2.37l-.29-.04c-1.31-.16-2.39-1.39-2.39-2.72V4.66c0-.79.31-1.51.88-2.03s1.31-.77 2.09-.7c2.19.18 5.51 1.28 7.39 2.45l.24.14c.07.03.22.04.28 0l.16-.1c.67-.42 1.51-.83 2.43-1.2a.76.76 0 0 1 .7.08c.21.14.33.37.33.62V6.6l.83-.55c.25-.17.58-.17.83 0l.83.55V2.78c0-.36.26-.67.61-.74.29-.05.58-.09.84-.11h.12c.73-.06 1.48.19 2.04.71.57.52.88 1.24.88 2.03v12.07c0 1.34-1.08 2.56-2.4 2.72l-.33.04c-2.06.27-5.36 1.36-7.19 2.37-.22.14-.52.21-.82.21M3.98 3.41c-.32 0-.61.11-.84.32-.25.23-.39.56-.39.93v12.08c0 .59.51 1.16 1.08 1.24l.3.04c2.25.3 5.7 1.43 7.7 2.53.09.04.22.05.27.03 2.01-1.11 5.47-2.25 7.73-2.55l.34-.04c.57-.07 1.08-.65 1.08-1.24V4.68c0-.37-.14-.69-.39-.93-.25-.23-.6-.34-.96-.32h-.14V8c0 .28-.15.53-.4.66s-.54.12-.77-.04l-1.58-1.05-1.58 1.05c-.23.15-.52.17-.77.04a.75.75 0 0 1-.4-.66V5.07c-.43.21-.82.42-1.16.62l-.16.1c-.55.34-1.33.34-1.86.01l-.24-.15C9.15 4.59 6.07 3.57 4.11 3.41z"
    />
    <path
      fill="currentColor"
      d="M12 21.24c-.41 0-.75-.34-.75-.75v-15c0-.41.34-.75.75-.75s.75.34.75.75v15c0 .42-.34.75-.75.75M19 8.75c-.15 0-.29-.04-.42-.13L17 7.57l-1.58 1.05c-.23.15-.52.17-.77.04a.76.76 0 0 1-.4-.66V3.92c0-.31.19-.58.47-.7 1.38-.55 2.89-.98 4.16-1.18.22-.04.44.03.61.17s.26.35.26.57V8c0 .28-.15.53-.4.66a.73.73 0 0 1-.35.09m-2-2.83c.14 0 .29.04.42.13l.83.55V3.69c-.8.18-1.67.44-2.5.75V6.6l.83-.55a.74.74 0 0 1 .42-.13"
    />
  </svg>
);
export default SvgBookSaved;
